<template>
  <van-popup v-model:show="show" class="full-height min-w-screen">
    <full-page-loader :loading="isLoading"/>
    <div class="flex items-center px-3">
      <van-icon class="flip-icon van-gray-6" name="arrow-left" size="22" @click="closeSearch"></van-icon>
      <niceone-search ref="searchRef" v-model="search" :placeholder="$t('search.search_brand_here')"
                      :show-action="!!search"
                      class="w-full"
                      @search="searchWord">
        <template #action>
          <div @click="search = ''">{{ $t('common.cancel') }}</div>
        </template>
      </niceone-search>
    </div>

    <search-list v-if="!isLoading && search" :search="search" @close="show = false"/>
    <div v-if="!isLoading && !search" class="p-3">
      <stored-searched-word @clearRecentSearchHistory="clearRecentSearchHistory" @getSeoUrl="getSeoUrl"/>
      <div class="mb-2">{{ $t('search.popular_searches') }}</div>
      <div class="flex flex-wrap gap-3">
        <div v-for="(item, index) in trends.popular_keywords" :key="`popular-search-${index}`"
             class="rounded-full border px-2 py-1 text-[13px] cursor-pointer" @click="navigate(item)">
          <nuxt-link :to="$routerUrl('search/?q='+item.keyword)">
            {{ item.keyword }}
          </nuxt-link>
        </div>
      </div>
      <div class="mt-3 mb-2">{{ $t('search.recommended_products') }}</div>
      <div class="flex flex-wrap gap-2">
        <nuxt-link v-for="(item, index) in trends.popular_manufacturers" :key="`recommended-product-${index}`"
                   :to="$routerUrl(item.seo_url)">
          <niceone-image :src="item.image" class="border rounded-md p-2 cursor-pointer" width="80"/>
        </nuxt-link>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
import {getSearchTrends} from "@/plugins/API/search"
import SearchList from "@/components/home/SearchList"
import StoredSearchedWord from "@/components/home/StoredSearchedWord"
import {recentSuggestionWord} from "@/composables/useStoreWord";
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";
import {COOKIE_KEYS} from "~/constants/cookie";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  initialValue: {
    type: String,
    default: ''
  }
})

const searchRef = ref(null)
onMounted(() => {
  searchRef.value?.focus();
})

const emit = defineEmits(['update:modelValue'])

const show = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const enabled = computed(() => props.modelValue)
const {isLoading, data} = getSearchTrends({
  enabled
})
const trends = computed(() => data?.value || {})

const search = ref(props.initialValue || '')

const router = useRouter();
const localPath = useLocalePath()
const searchWord = () => {
  track(TRACKERS.SEARCH_TERM, {
    eventLabel: search.value,
    eventAction: 'search_term'
  })
  recentSuggestionWord({term: search.value, search_query: search.value})
  router.push(localPath({path: '/search', query: {q: search.value}}))
  closeSearch()
}
const closeSearch = () => {
  track(TRACKERS.SEARCH_TERM, {
    eventLabel: search.value,
    eventAction: 'close'
  })
  show.value = false
}

const searchSuggestions = useCookie(COOKIE_KEYS.SEARCH_SUGGESTIONS, {secure: true})
const clearRecentSearchHistory = () => {
  searchSuggestions.value = null
  closeSearch()
}

const getSeoUrl = (val) => {
  router.push(localPath({path: '/search', query: {q: val.search_query}}))
  closeSearch()
}
const navigate = (val) => {
  search.value = val.keyword
  recentSuggestionWord({term: val.keyword, search_query: val.keyword})
  show.value = false
}
</script>